import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components
import { getManageNotification as onGetManageNotification } from 'store/actions'

import {
  Location,
  Status,
  Type,
  AccountName,
  ContentName,
  CreatedDate,
  StatusAccount,
  ContentNo,
  UpdatetedDate,
  DisplayDate,
  ListReceivers,
  BaseString,
  Datetime
} from './ManageNotificationCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ManageContentVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  acceptMemberAPI,
  cancleNotificationAPI,
  deleteContentAPI,
  exportListAPI,
  lockAccountAPI,
  patchStatusContentAPI
} from 'helpers/backend_helper'
import { checkIsLocked, formateDate, getStorage, setStorage } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import { getContentDetailSuccess } from 'store/actions'
import { ANNOUNCE_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'

function ManageNotification() {
  //meta title
  document.title = 'Content List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.ANNOUNCE_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectNotificationsState = (state) => state.NotificationReducer
  const ContentsProperties = createSelector(selectNotificationsState, (notificationReducer) => ({
    notifications: notificationReducer.notifications,
    loading: notificationReducer.loading,
    total: notificationReducer.total
  }))

  const { notifications, loading, total } = useSelector(ContentsProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    // toast("thành công ", { type: "success" })
    const filterManageNotification = getStorage('filterManageNotification')

    dispatch(onGetManageNotification({ ...filterManageNotification, page: 1 }))
  }, [dispatch])

  const onClickDelete = (id) => {
    setSelected(id)
    setShowDelete(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'id',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentNo {...cellProps} page={page} />
        }
      },
      {
        Header: 'Tiêu đề thông báo',
        accessor: 'title',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Tài khoản nhận thông báo',
        accessor: 'receivers',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ListReceivers {...cellProps} />
        }
      },
      {
        Header: 'Thời gian gửi thông báo',
        accessor: 'sendDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Datetime {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },

      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          // console.log(cellProps)
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={ANNOUNCE_MANAGEMENT_CODE.ADMIN_DETAIL_ANNOUNCE}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`/manage-notification/view/${cellProps.row.original.id}`}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              {cellProps.row.original.status === 'pending' && (
                <PermissionWrapper
                  screenCode={screenCode}
                  code={[
                    ANNOUNCE_MANAGEMENT_CODE.ADMIN_DETAIL_ANNOUNCE,
                    ANNOUNCE_MANAGEMENT_CODE.ADMIN_UPDATE_ANNOUNCE
                  ]}
                >
                  <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                    <Link
                      to={`/manage-notification/edit/${cellProps.row.original.id}`}
                      className='btn btn-sm btn-soft-info'
                      id={`edittooltip-${cellProps.row.original.id}`}
                    >
                      <i className='mdi mdi-pencil-outline' />
                      <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                        Sửa
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                </PermissionWrapper>
              )}

              <PermissionWrapper screenCode={screenCode} code={ANNOUNCE_MANAGEMENT_CODE.ADMIN_DELETE_ANNOUNCE}>
                {cellProps.row.original.status === 'pending' && (
                  <li>
                    <Link
                      to='#'
                      className={`btn btn-sm btn-soft-danger`}
                      onClick={() => {
                        const programData = cellProps.row.original
                        onClickDelete(programData)
                      }}
                      id={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      <i className='mdi mdi-minus-circle-outline' />
                      <UncontrolledTooltip
                        trigger='hover'
                        placement='top'
                        target={`deletetooltip-${cellProps.row.original.id}`}
                      >
                        {/* {cellProps.row.original?.status !== "draft" ? "Đã phê duyệt không thể xóa" : "Xóa"} */}
                        Huỷ
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                )}
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [filter, page, notifications]
  )
  const [itemSelect, setItemSelect] = useState()

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        onGetManageNotification({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(onGetManageNotification({ ...filter, sort_field: '', sort_order: '' }))
    }
  }

  const onDelete = async () => {
    try {
      const res = await cancleNotificationAPI({
        id: selected.id
      })
      if (res) {
        toast('Huỷ thông báo thành công', { type: 'success' })
        dispatch(onGetManageNotification({ page: page }))
        setShowDelete(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onUpdateRequest = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: selected.id,
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        // dispatch(getContentDetail(params.id))
        dispatch(onGetManageNotification({ ...filter, page: page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  const closePopup = () => {
    setShowPause(false)
    setShowPlay(false)
  }

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    setStorage('filterManageNotification', data)

    setFilter({ ...data, page: 1 })
    dispatch(onGetManageNotification(data))
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(onGetManageNotification({ ...filter, page: page }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Quản lý thông báo'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách thông báo'}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={ANNOUNCE_MANAGEMENT_CODE.ADMIN_CREATE_ANNOUNCE}
                        >
                          <button
                            onClick={() => {
                              dispatch(getContentDetailSuccess(null))
                              navigate('/manage-notification/create')
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {ManageContentVN.addAction}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={notifications}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageNotificationGlobalFilter={true}
                      placeholderSearch={'Nhập tiêu đề, nội dung'}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      setSortBy={handleSort}
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-alert-outline'></i>
          </div>
        }
        onConfirmClick={onDelete}
        onCloseClick={() => setShowDelete(false)}
        title='Huỷ thông báo gửi'
        description='Bạn có chắc chắn muốn huỷ thông báo không?'
      />
      <ConfirmModal
        show={showPause}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        hasInput
        onConfirmClick={(note) => {
          onUpdateRequest('pause', note)
        }}
        onCloseClick={() => setShowPause(false)}
        title='Xác nhận tạm ngưng bài viết'
        description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
      />
      <ConfirmModal
        show={showPlay}
        onConfirmClick={(note) => {
          onUpdateRequest('active')
        }}
        onCloseClick={() => setShowPlay(false)}
        // hasInput
        title={'Xác nhận hiển thị lại bài viết'}
        description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
      />
    </React.Fragment>
  )
}

export default ManageNotification
