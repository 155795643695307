import { PermissionVN, BankVN } from 'constants/vn'
import React, { useEffect, useState, useMemo } from 'react'
import { CardBody, Col, Label, Modal, ModalBody, Row } from 'reactstrap'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { setTypePopupBank, getBankList, setTypePopupContact, getContactList } from 'store/actions'
import {
  getZoneAPI,
  getAreaManagementOptionsAPI,
  postCreateBankAPI,
  updateBankAPI,
  postCreateContactAPI,
  createContactAPI,
  updateContactAPI
} from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Form, Tree, Switch, Divider, Input, Spin, InputNumber } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
// import { optionsGeographicalLevel, AREA_TYPE } from '../constants'
import ConfirmModal from 'components/Common/ConfirmModal'
import { isEqual } from 'lodash'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import TableContainer from 'components/Common/TableContainer'
import { formateDateTime } from 'helpers/global_helper'

const auditLogDetailModal = ({ show, onCloseClick, selected, filter, setPage, dataTable }) => {
  const dispatch = useDispatch()

  const selectContactsState = (state) => state.ContactsReducer
  const ContactsProperties = createSelector(selectContactsState, (reducer) => ({
    type: reducer.type,
    contactDetail: reducer.contactDetail
  }))
  const { type, contactDetail } = useSelector(ContactsProperties)

  const isEdit = type !== 'view'
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [valueGeographicalLevel, setValueGeographicalLevel] = useState(AREA_TYPE.ZONE)
  const [zone, setZone] = useState()
  const [subZone, setSubZone] = useState()
  const [province, setProvince] = useState()
  const [district, setDistrict] = useState()
  const [onCancel, setOnCancel] = useState(false)
  const [regex, setRegex] = useState({
    regex: /^\b\d{1}\b/,
    message: 'Mã Miền phải gồm 1 ký tự'
  })
  const [initData, setInitData] = useState()

  useEffect(() => {
    if (type == 'create') {
    }
  }, [type])

  useEffect(() => {
    if (contactDetail) {
      setInitData({
        id: contactDetail.id,
        email: contactDetail.email,
        phone: contactDetail.phone,
        address: contactDetail.address,
        label: contactDetail.label
      })

      form.setFieldsValue({
        id: contactDetail.id,
        email: contactDetail.email,
        phone: contactDetail.phone,
        address: contactDetail.address,
        label: contactDetail.label
      })
      setValueGeographicalLevel(contactDetail.type)
    }
  }, [contactDetail])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabledSubmit(false)
      },
      () => {
        setDisabledSubmit(true)
      }
    )
  }, [watchForm])

  const onFinish = async (values) => {
    if (type == 'view') {
      dispatch(setTypePopupContact('edit'))
      setDisabledSubmit(false)
      return
    }
    setLoading(true)
    try {
      const payload = {
        email: values?.email ? values?.email : '',
        phone: values?.phone ? values?.phone : '',
        address: values?.address ? values?.address : '',
        label: values?.label ? values?.label : ''
      }

      if (type == 'create') {
        const res = await createContactAPI({ ...payload })
        if (res) {
          // toast('Thêm mới ngân hàng thành công', { type: 'success' })
          toast('Thêm mới thông tin liên hệ thành công', { type: 'success' })
          onCloseClick()
          dispatch(getContactList())
          setPage(1)
        }
      } else {
        const res = await updateContactAPI({ ...payload, id: contactDetail.id })

        if (res) {
          // toast('Cập nhật ngân hàng thành công', { type: 'success' })
          toast('Cập nhật thông tin liên hệ thành công', { type: 'success' })
          onCloseClick()
          dispatch(getContactList({ ...filter }))
          dispatch(getContactList({ ...filter }))
        }
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Loại',
        accessor: 'alias',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Thông tin cũ',
        accessor: 'oldMetadata',
        filterable: true,
        hideSort: true
      },
      {
        Header: 'Thông tin mới',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,

        Cell: (cellProps) => {
          if (cellProps?.value?.oldMetadata === cellProps?.value?.newMetadata) {
            return <></>
          } else {
            return cellProps?.value?.newMetadata
          }
        }
      }
    ],
    []
  )

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <Spin spinning={loading}>
            <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0' />
            <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Chi tiết thay đổi thông tin sản phẩm'}</h4>
            <TableContainer
              columns={columns}
              data={dataTable}
              isGlobalFilter={false}
              isManageBankGlobalFilter={false}
              isPagination={false}
              iscustomPageSizeOptions={false}
              isShowingPageLength={false}
              customPageSize={11}
              placeholderSearch={BankVN.filters.search.placeholder}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
              useAPIChangePaging
            />
          </Spin>
        </ModalBody>
      </div>
      <ConfirmModal
        show={onCancel}
        onConfirmClick={onClickCancel}
        onCloseClick={() => setOnCancel(false)}
        title={`Xác nhận`}
        description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
      />
    </Modal>
  )
}
export default auditLogDetailModal
